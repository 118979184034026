import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSignInAlt, faExclamationTriangle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

type SchemaType = {
  name: string,
  comment: string
}

type LoginProps = {
  schema: string,
  onLogin?: (user: any) => void,
}

interface SchemasProps {
  schemas: Array<SchemaType>,
  schema: string,
  onChange: ((e: React.ChangeEvent<HTMLInputElement>) => void)
}

function SchemasList(props: SchemasProps){
  if(props.schemas.length>1)
  return (
    <Form.Group controlId="exampleForm.ControlSelect1">
      <Form.Label>Выберите схему</Form.Label>
      <Form.Control as="select" onChange={props.onChange} defaultValue="{props.schema}">
        <option value="">...</option>
        {props.schemas.map(
          (value: SchemaType, index: number) => 
            <option value={value.name} key={index}>
              {value.comment}
            </option>
        )}  
      </Form.Control>
    </Form.Group>
  );
  return null;
}

export class Login extends React.Component<LoginProps> {
  constructor(props: LoginProps){
    super(props);
    this.state.schema = props.schema;
  }
  static defaultProps = {
    schema: ''
  }
  state = {
    show: '',
    email: '',
    password: '',
    schema: '',
    schemas: [],
    error: '',
    user_name: ''
  }
  
  async componentDidMount(){
    if(this.props.schema){
      try{
        const response = await fetch('/backend/login', {
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json',
              }
            });
        if(response.status!==200)
          throw Error('Неавторизован');
        const user = await response.json();
        if (this.props.onLogin)
            this.props.onLogin(user);
        this.setState({user_name: user['name']});
      }
      catch(err){}
    }
  }
  async handleSubmit(event: React.FormEvent) {
    this.setState({error: ''});
    const form = event.target as HTMLFormElement;
    event.preventDefault();
    
    if(!this.state.schema){
      // Запрашиваем доступные схемы
      try {
        const response = await fetch('/backend/schemas', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(this.state.email + ':' + this.state.password),
          },
        });
        if(response.status!==200)
          throw Error('Неправильный логин или пароль');
        const body = await response.json();
        if(!Array.isArray(body) || !body.length)
          throw Error("Схемы недоступны");
        // Если доступна одна схема, то устанавливаем её и продолжаем выполнение функции
        if(body.length === 1)
          this.setState({schema: body[0].name});
        else {
          // Показать список схем с описанием и прекращаем выполнение функции
          this.setState({schemas: body});
          return;
        }
      }
      catch (err){
        // Отобразить описание ошибки
        this.setState({error: err.message});
        return;
      }
    };
    const schema_url = '//' + (this.props.schema === this.state.schema? '': this.state.schema + '.') + window.location.host;
    
    form.action = schema_url + '/backend/login';
    form.submit();
  }

  render() {
    return (
      <>
        <Button className="ml-auto" variant="outline-primary" onClick={() => this.setState({show: 'login'})}>
          <FontAwesomeIcon icon={this.state.user_name?faUser:faSignInAlt} />
        </Button>
        <Modal show={this.state.show==='login'} onHide={() => this.setState({show: ''})}>
        <Form onSubmit={this.handleSubmit.bind(this)} method="post">
            <Modal.Header closeButton>
              <Modal.Title>Вход в систему</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.user_name &&
                <div>
                  Вы вошли в систему как {this.state.user_name}
                  <hr />
                </div>
              }
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" placeholder="Введите email" onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({email: e.currentTarget.value, schemas: [], schema: this.props.schema})} value={this.state.email}/>
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Пароль</Form.Label>
                <Form.Control type="password" name="password" placeholder="Пароль"  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({password: e.currentTarget.value, schemas: [], schema: this.props.schema})} value={this.state.password}/>
              </Form.Group>
            <SchemasList schema={this.state.schema} schemas={this.state.schemas} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({schema: e.currentTarget.value})} />
            {this.state.error &&
              <Alert variant="danger">
                <FontAwesomeIcon icon={faExclamationTriangle} /> {this.state.error}
              </Alert>
            }
            </Modal.Body>
            <Modal.Footer>
              {this.state.user_name &&
                <a href="/backend/logout" className='btn btn-danger'>
                  <FontAwesomeIcon icon={faSignOutAlt} /> Выйти
                </a>
              }
              <Button variant="secondary" onClick={() => this.setState({show: ''})}>
                Отмена
              </Button>
              <Button variant="primary" type="submit" disabled={!this.state.email||!this.state.password||(!this.state.schema&&this.state.schemas.length>1)}>
                {this.state.schema?<><FontAwesomeIcon icon={faSignInAlt} /> Войти</>:'Далее'}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>  
    );
  }
}

export default Login;
